import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const Container = styled.div<{ $isOpen: boolean }>`
  border: 1px solid ${Colors.Primary};
  padding: 8px;

  button svg {
    transition: transform 0.2s ease-out;
    transform: rotate(-90deg);
    width: 0.8em;
  }

  ${({ $isOpen }) => {
    if ($isOpen) {
      return css`
        border: 1px solid ${Colors.Contrast};
        button svg {
          transform: rotate(0deg);
        }
        svg {
          color: ${Colors.Contrast};
        }
      `;
    }
  }};

  ${media.from_tablet`
    padding: 10px 10px 5px;
  `}
`;
