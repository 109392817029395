import styled from 'styled-components';

import { FaqProps } from './Faq.types';
import { Container } from './Faq.styles';
import { ChevronDownIcon, CreditCardIcon, InfoIcon, LawIcon } from '../Icons';
import { IconButton } from '../Icons/Icon';
import { media } from '../../../styles/media';
import { useState } from 'react';
import { Colors } from '../../../styles/colors';

export const Faq = ({ children, title, category = 'general', isOpen = true }: React.PropsWithChildren<FaqProps>) => {
  const [isFaqOpen, setIsFaqOpen] = useState<boolean>(isOpen);
  let icon = <InfoIcon size={1.3} color={Colors.Primary} />;
  switch (category) {
    case 'payment':
      icon = <CreditCardIcon size={1.3} color={Colors.Primary} />;
      break;
    case 'legal':
      icon = <LawIcon size={1.3} color={Colors.Primary} />;
      break;
    case 'general':
      icon = <InfoIcon size={1.3} color={Colors.Primary} />;
      break;
    default:
      icon = <InfoIcon size={1.3} color={Colors.Primary} />;
      break;
  }
  return (
    <Container $isOpen={isFaqOpen}>
      <TitleContainer>
        <TitleWrapper>
          <IconWrapper>{icon}</IconWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        <IconButton
          onClick={() => {
            setIsFaqOpen(!isFaqOpen);
          }}
        >
          <ChevronDownIcon />
        </IconButton>
      </TitleContainer>
      {isFaqOpen && <ContentContainer>{children}</ContentContainer>}
    </Container>
  );
};

export const Faqs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  ${media.from_tablet`
    grid-template-columns: 1fr 1fr;
  `};
`;

const Title = styled.h4`
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

const Action = styled.button``;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 0 0 24px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
